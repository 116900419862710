import { Menu } from "antd";
import { Link, useMatches, useSearchParams } from "react-router-dom";
import { navigationMenuRoutes } from "../../routes";

function NavigationMenu() {
  const [searchParams, setSearchParams] = useSearchParams();
  const infrastructureId = searchParams.get("infrastructureId");
  const matches = useMatches();

  const items = navigationMenuRoutes.map((route) => ({
    disabled: route.disabled || false,
    key: route.id,
    icon: <route.icon style={{ color: "white" }} />,
    label: (
      <Link to={`${route.path}${infrastructureId ? `?infrastructureId=${infrastructureId}` : ""}`}>
        <span style={{ color: "white" }}>{route.title}</span>
      </Link>
    ),
  }));

  return <Menu mode="inline" items={items} selectedKeys={[matches[1].id]} theme="dark" style={{ backgroundColor: "transparent" }} />;
}

export default NavigationMenu;
