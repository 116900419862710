import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export const useUser = () => {
  const [user, setUser] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups && groups.includes("DepartureSimulation")) {
        user.attributes.DepartureSimulation = true;
      } else {
        user.attributes.DepartureSimulation = false;
      }
      setUser(user.attributes);
    });
  }, []);
  return user || {};
};
