"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findInfrastructureTemplate = exports.infrastructureTemplates = exports.DEFAULT_SCREENING_PASSENGER_PROPORTION = exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE = exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION = exports.DEFAULT_DEPARTURE_PASSENGER_PROPORTION = exports.DEFAULT_ARRIVAL_PASSENGER_PROPORTION = void 0;
exports.DEFAULT_ARRIVAL_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.55,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.45,
        label: "Third Country Nationals",
        subgroups: [
            {
                id: "vh",
                proportion: 0.67,
                label: "Visa Holders",
                subgroups: [
                    {
                        id: "first_registration",
                        proportion: 0.67,
                        label: "First registration",
                    },
                    {
                        id: "reentry",
                        proportion: 0.33,
                        label: "Re-entry",
                    },
                ],
            },
            {
                id: "ve",
                proportion: 0.33,
                label: "Visa Exempt",
                subgroups: [
                    {
                        id: "first_registration",
                        proportion: 0.67,
                        label: "First registration",
                    },
                    {
                        id: "reentry",
                        proportion: 0.33,
                        label: "Re-entry",
                    },
                ],
            },
        ],
    },
];
exports.DEFAULT_DEPARTURE_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.55,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.45,
        label: "Third Country Nationals",
        subgroups: [
            {
                id: "vh",
                proportion: 0.67,
                label: "Visa Holders",
                subgroups: [
                    {
                        id: "first_registration",
                        proportion: 0.67,
                        label: "First registration",
                    },
                    {
                        id: "reentry",
                        proportion: 0.33,
                        label: "Re-entry",
                    },
                ],
            },
            {
                id: "ve",
                proportion: 0.33,
                label: "Visa Exempt",
                subgroups: [
                    {
                        id: "first_registration",
                        proportion: 0.67,
                        label: "First registration",
                    },
                    {
                        id: "reentry",
                        proportion: 0.33,
                        label: "Re-entry",
                    },
                ],
            },
        ],
    },
];
exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION = [
    {
        id: "bag",
        proportion: 0.65,
        label: "With bags",
        subgroups: [
            {
                id: "ci",
                proportion: 0.8,
                label: "Off-hall check-in",
                subgroups: [
                    {
                        id: "bagtag",
                        proportion: 0.67,
                        label: "Off-hall bag tag",
                    },
                    {
                        id: "nobagtag",
                        proportion: 0.33,
                        label: "In-hall bag tag",
                    },
                ],
            },
            {
                id: "noci",
                proportion: 0.2,
                label: "In-hall check-in",
                subgroups: [
                    {
                        id: "convci",
                        proportion: 0.33,
                        label: "Conventional Check-in",
                    },
                    {
                        id: "diyci",
                        proportion: 0.67,
                        label: "DIY Check-in",
                    },
                ],
            },
        ],
    },
    {
        id: "nobag",
        proportion: 0.35,
        label: "Without bags",
    },
];
exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE = [
    {
        id: "bag_ci_bagtag_pax_leisure",
        label: "Leisure pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_leisure",
        label: "Leisure pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_leisure",
        label: "Leisure pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_leisure",
        label: "Leisure pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_longhaul",
        label: "Long haul pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_longhaul",
        label: "Long haul pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_longhaul",
        label: "Long haul pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_longhaul",
        label: "Long haul pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_shorthaul",
        label: "Short haul pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_shorthaul",
        label: "Short haul pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_shorthaul",
        label: "Short haul pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_shorthaul",
        label: "Short haul pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_hclonghaul",
        label: "Long haul HC pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_hclonghaul",
        label: "Long haul HC pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_hclonghaul",
        label: "Long haul HC pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_hclonghaul",
        label: "Long haul HC pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_hcshorthaul",
        label: "Short haul HC pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_hcshorthaul",
        label: "Short haul HC pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_hcshorthaul",
        label: "Short haul HC  pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_hcshorthaul",
        label: "Short haul HC pax, conventional check-in",
    },
    {
        id: "bag_ci_bagtag_pax_lowcost",
        label: "Low cost pax, has already checked-in and bag tag",
    },
    {
        id: "bag_ci_nobagtag_pax_lowcost",
        label: "Low cost pax, has checked-in, needs bag tag",
    },
    {
        id: "bag_noci_diyci_pax_lowcost",
        label: "Low cost pax, DIY Check-in, bag tag and bag drop",
    },
    {
        id: "bag_noci_convci_pax_lowcost",
        label: "Low cost pax, conventional check-in",
    },
];
exports.DEFAULT_SCREENING_PASSENGER_PROPORTION = [
    {
        id: "eu",
        proportion: 0.55,
        label: "EU Pax",
    },
    {
        id: "tcn",
        proportion: 0.45,
        label: "Third Country Nationals",
        subgroups: [
            {
                id: "vh",
                proportion: 0.67,
                label: "Visa Holders",
            },
            {
                id: "ve",
                proportion: 0.33,
                label: "Visa Exempt",
            },
        ],
    },
];
const DEFAULT_PLANNING = [
    {
        hour: 0,
        open_items: 0,
    },
    {
        hour: 1,
        open_items: 0,
    },
    {
        hour: 2,
        open_items: 0,
    },
    {
        hour: 3,
        open_items: 0,
    },
    {
        hour: 4,
        open_items: 0,
    },
    {
        hour: 5,
        open_items: 0,
    },
    {
        hour: 6,
        open_items: 0,
    },
    {
        hour: 7,
        open_items: 0,
    },
    {
        hour: 8,
        open_items: 0,
    },
    {
        hour: 9,
        open_items: 0,
    },
    {
        hour: 10,
        open_items: 0,
    },
    {
        hour: 11,
        open_items: 0,
    },
    {
        hour: 12,
        open_items: 0,
    },
    {
        hour: 13,
        open_items: 0,
    },
    {
        hour: 14,
        open_items: 0,
    },
    {
        hour: 15,
        open_items: 0,
    },
    {
        hour: 16,
        open_items: 0,
    },
    {
        hour: 17,
        open_items: 0,
    },
    {
        hour: 18,
        open_items: 0,
    },
    {
        hour: 19,
        open_items: 0,
    },
    {
        hour: 20,
        open_items: 0,
    },
    {
        hour: 21,
        open_items: 0,
    },
    {
        hour: 22,
        open_items: 0,
    },
    {
        hour: 23,
        open_items: 0,
    },
];
exports.infrastructureTemplates = [
    {
        id: "departure-hall-current",
        name: "Departure hall current",
        airport_component: "DepartureCurrent",
    },
    {
        id: "departure-hall-future",
        name: "Departure hall future",
        airport_component: "DepartureFuture",
        start_point: "entry",
        passenger_proportions: exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION,
        passenger_types: exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE,
        entities: [
            {
                name: "checkin-kiosk",
                process_order: 0,
                display_order: 2,
                label: "Check-in Kiosks",
                type: "entity",
                elements: [
                    {
                        name: "checkin-kiosk-element",
                        label: "Check-in Kiosk",
                        n_entities: 14,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "checkin-kiosk-element": ["checkin-kiosk-element"],
                },
            },
            {
                name: "bagtag-kiosk",
                label: "Bag Tag Printing kiosk",
                process_order: 1,
                display_order: 1,
                type: "entity",
                elements: [
                    {
                        name: "bagtag-kiosk-elements",
                        label: "Bag Tag Printing kiosk",
                        n_entities: 88,
                        min_entities: 0,
                        max_entities: 200,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagtag-kiosk-elements": ["bagtag-kiosk-elements"],
                },
            },
            {
                name: "bagdrop",
                process_order: 2,
                display_order: 0,
                label: "Bag Drop Desk",
                type: "entity",
                elements: [
                    {
                        name: "bagdrop-all",
                        label: "Bag Drop Desk - All airlines",
                        n_entities: 52,
                        min_entities: 0,
                        max_entities: 200,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagdrop-all": ["bagdrop-all"],
                },
            },
            {
                name: "conv-ci",
                process_order: 3,
                display_order: 3,
                label: "Conventional Check-in Desk",
                type: "entity",
                elements: [
                    {
                        name: "conv-ci-desk",
                        label: "Conventional Check-in Desk",
                        n_entities: 77,
                        min_entities: 0,
                        max_entities: 200,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "conv-ci-desk": ["conv-ci-desk"],
                },
            },
        ],
    },
    {
        id: "departure-hall-future-by-sectors",
        name: "Departure hall future by sectors",
        airport_component: "DepartureFutureBySectors",
        start_point: "entry",
        passenger_proportions: exports.DEFAULT_DEPARTURE_HALL_PASSENGER_PROPORTION,
        passenger_types: exports.DEFAULT_DEPARTURE_HALL_PASSENGER_TYPE,
        entities: [
            {
                name: "checkin-kiosk",
                process_order: 0,
                display_order: 2,
                label: "Check-in Kiosks",
                type: "entity",
                elements: [
                    {
                        name: "checkin-kiosk-element-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 1,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 2,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-shorthaul",
                        label: "Short Haul",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-longhaul",
                        label: "Long Haul",
                        n_entities: 2,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-leisure",
                        label: "Leisure",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "checkin-kiosk-element-lowcost",
                        label: "Lowcost",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 40,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 60,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "checkin-kiosk-element-lowcost": ["checkin-kiosk-element-lowcost"],
                    "checkin-kiosk-element-leisure": ["checkin-kiosk-element-leisure"],
                    "checkin-kiosk-element-longhaul": ["checkin-kiosk-element-longhaul"],
                    "checkin-kiosk-element-shorthaul": ["checkin-kiosk-element-shorthaul"],
                    "checkin-kiosk-element-hcshorthaul": ["checkin-kiosk-element-hcshorthaul"],
                    "checkin-kiosk-element-hclonghaul": ["checkin-kiosk-element-hclonghaul"],
                },
            },
            {
                name: "bagtag-kiosk",
                label: "Bag Tag Printing Kiosk",
                process_order: 1,
                display_order: 1,
                type: "entity",
                elements: [
                    {
                        name: "bagtag-kiosk-elements-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 18,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 7,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-shorthaul",
                        label: "Short Haul",
                        n_entities: 23,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-longhaul",
                        label: "Long Haul",
                        n_entities: 9,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-leisure",
                        label: "Leisure",
                        n_entities: 15,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "bagtag-kiosk-elements-lowcost",
                        label: "Lowcost",
                        n_entities: 16,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "checkin-kiosk",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 90,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagtag-kiosk-elements-lowcost": ["bagtag-kiosk-elements-lowcost"],
                    "bagtag-kiosk-elements-leisure": ["bagtag-kiosk-elements-leisure"],
                    "bagtag-kiosk-elements-longhaul": ["bagtag-kiosk-elements-longhaul"],
                    "bagtag-kiosk-elements-shorthaul": ["bagtag-kiosk-elements-shorthaul"],
                    "bagtag-kiosk-elements-hclonghaul": ["bagtag-kiosk-elements-hclonghaul"],
                    "bagtag-kiosk-elements-hcshorthaul": ["bagtag-kiosk-elements-hcshorthaul"],
                },
            },
            {
                name: "bagdrop",
                process_order: 2,
                display_order: 0,
                label: "Bag Drop",
                type: "entity",
                elements: [
                    {
                        name: "bagdrop-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 10,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hcshorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 4,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_hclonghaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-shorthaul",
                        label: "Short Haul",
                        n_entities: 13,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_shorthaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-longhaul",
                        label: "Long Haul",
                        n_entities: 6,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_longhaul",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-leisure",
                        label: "Leisure",
                        n_entities: 9,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_leisure",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "bagdrop-lowcost",
                        label: "Lowcost",
                        n_entities: 10,
                        min_entities: 0,
                        max_entities: 100,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_bagtag_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_noci_diyci_pax_lowcost",
                            },
                            {
                                source: "bagtag-kiosk",
                                frac: 1,
                                handling_time: 45,
                                passenger_type: "bag_ci_nobagtag_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "bagdrop-lowcost": ["bagdrop-lowcost"],
                    "bagdrop-leisure": ["bagdrop-leisure"],
                    "bagdrop-longhaul": ["bagdrop-longhaul"],
                    "bagdrop-shorthaul": ["bagdrop-shorthaul"],
                    "bagdrop-hclonghaul": ["bagdrop-hclonghaul"],
                    "bagdrop-hcshorthaul": ["bagdrop-hcshorthaul"],
                },
            },
            {
                name: "conv-ci",
                process_order: 3,
                display_order: 3,
                label: "Conventional Check-in",
                type: "entity",
                elements: [
                    {
                        name: "conv-ci-desk-hcshorthaul",
                        label: "Home Carrier Short Haul",
                        n_entities: 16,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hcshorthaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-hclonghaul",
                        label: "Home Carrier Long Haul",
                        n_entities: 6,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_hclonghaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-shorthaul",
                        label: "Short Haul",
                        n_entities: 20,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_shorthaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-longhaul",
                        label: "Long Haul",
                        n_entities: 8,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_longhaul",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-leisure",
                        label: "Leisure",
                        n_entities: 13,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_leisure",
                            },
                        ],
                    },
                    {
                        name: "conv-ci-desk-lowcost",
                        label: "Lowcost",
                        n_entities: 14,
                        min_entities: 0,
                        max_entities: 50,
                        planning: [],
                        passenger_types: [
                            {
                                source: "entry",
                                frac: 1,
                                handling_time: 109,
                                passenger_type: "bag_noci_convci_pax_lowcost",
                            },
                        ],
                    },
                ],
                queues: {
                    "conv-ci-desk-lowcost": ["conv-ci-desk-lowcost"],
                    "conv-ci-desk-leisure": ["conv-ci-desk-leisure"],
                    "conv-ci-desk-longhaul": ["conv-ci-desk-longhaul"],
                    "conv-ci-desk-shorthaul": ["conv-ci-desk-shorthaul"],
                    "conv-ci-desk-hclonghaul": ["conv-ci-desk-hclonghaul"],
                    "conv-ci-desk-hcshorthaul": ["conv-ci-desk-hcshorthaul"],
                },
            },
        ],
    },
    {
        id: "security-screening-and-border",
        name: "Security screening and border",
        airport_component: "Screening",
        start_point: "start",
        passenger_proportions: exports.DEFAULT_SCREENING_PASSENGER_PROPORTION,
        passenger_types: [
            {
                id: "eu_pax_departure",
                label: "EU Pax Departure Pier B",
            },
            {
                id: "tcn_vh_pax_departure",
                label: "TCN Visa Holders Departure Pier B",
            },
            {
                id: "tcn_ve_pax_departure",
                label: "TCN Visa Exempt Departure Pier B",
            },
            {
                id: "eu_pax_screening_only",
                label: "EU Pax Departure Pier A/T",
            },
            {
                id: "tcn_vh_pax_screening_only",
                label: "TCN Visa Holders Departure Pier A/T",
            },
            {
                id: "tcn_ve_pax_screening_only",
                label: "TCN Visa Exempt Departure Pier A/T",
            },
            {
                id: "eu_pax_transfer",
                label: "EU Pax Transfer A->B",
            },
            {
                id: "tcn_vh_pax_transfer",
                label: "TCN Visa Holders Transfer A->B",
            },
            {
                id: "tcn_ve_pax_transfer",
                label: "TCN Visa Exempt Transfer A->B",
            },
        ],
        entities: [
            {
                name: "screening",
                process_order: 0,
                display_order: 2,
                label: "Connector Screening",
                type: "multi-queue-entity",
                elements: [
                    {
                        name: "screening-lane",
                        label: "Screening Lane",
                        n_entities: 21,
                        min_entities: 21,
                        max_entities: 21,
                        throughput: 30,
                        planning: DEFAULT_PLANNING,
                        mapping: [
                            {
                                n_elements: 4,
                                queue: "screening-section-1",
                                order: 0,
                            },
                            {
                                n_elements: 5,
                                queue: "screening-section-2",
                                order: 1,
                            },
                            {
                                n_elements: 8,
                                queue: "screening-section-3",
                                order: 2,
                            },
                            {
                                n_elements: 8,
                                queue: "screening-section-4",
                                order: 3,
                            },
                        ],
                        passenger_types: [
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "eu_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "tcn_vh_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "tcn_ve_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "eu_pax_screening_only",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "tcn_vh_pax_screening_only",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 24,
                                passenger_type: "tcn_ve_pax_screening_only",
                            },
                        ],
                    },
                ],
                queues: {
                    "screening-section-1": ["screening-section-1"],
                    "screening-section-2": ["screening-section-2"],
                    "screening-section-3": ["screening-section-3"],
                    "screening-section-4": ["screening-section-4"],
                },
            },
            {
                name: "post-screening-dwelling",
                process_order: 1,
                display_order: 0,
                label: "Dwelling zone",
                type: "transit",
                passenger_types: [
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "eu_pax_departure",
                    },
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "tcn_vh_pax_departure",
                    },
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "tcn_ve_pax_departure",
                    },
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "eu_pax_screening_only",
                    },
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "tcn_vh_pax_screening_only",
                    },
                    {
                        source: "screening",
                        frac: 1,
                        handling_time: 60,
                        passenger_type: "tcn_ve_pax_screening_only",
                    },
                ],
            },
            {
                name: "border",
                process_order: 2,
                display_order: 1,
                label: "Border Control",
                type: "entity",
                elements: [
                    {
                        name: "eu-booth",
                        label: "EU Booths",
                        n_entities: 5,
                        min_entities: 0,
                        max_entities: 10,
                        planning: DEFAULT_PLANNING,
                        passenger_types: [
                            {
                                source: "post-screening-dwelling",
                                frac: 0.33,
                                handling_time: 24,
                                passenger_type: "eu_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 0.33,
                                handling_time: 24,
                                passenger_type: "eu_pax_transfer",
                            },
                        ],
                    },
                    {
                        name: "tcn-booth",
                        label: "TCN Booths",
                        n_entities: 3,
                        min_entities: 0,
                        max_entities: 10,
                        planning: DEFAULT_PLANNING,
                        passenger_types: [
                            {
                                source: "post-screening-dwelling",
                                frac: 1,
                                handling_time: 85,
                                passenger_type: "tcn_vh_pax_departure",
                            },
                            {
                                source: "post-screening-dwelling",
                                frac: 1,
                                handling_time: 85,
                                passenger_type: "tcn_ve_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 85,
                                passenger_type: "tcn_vh_pax_transfer",
                            },
                            {
                                source: "start",
                                frac: 1,
                                handling_time: 85,
                                passenger_type: "tcn_ve_pax_transfer",
                            },
                        ],
                    },
                ],
                queues: {
                    "eu-booth": ["eu-booth", "tcn-booth"],
                    "tcn-booth": ["tcn-booth", "eu-booth"],
                },
            },
            {
                name: "egates",
                process_order: 3,
                display_order: 3,
                label: "eGates",
                type: "entity",
                queues: {
                    "eu-egates": ["eu-egates"],
                },
                elements: [
                    {
                        name: "eu-egates",
                        label: "EU eGates",
                        n_entities: 10,
                        min_entities: 0,
                        max_entities: 10,
                        planning: DEFAULT_PLANNING,
                        passenger_types: [
                            {
                                source: "post-screening-dwelling",
                                frac: 0.67,
                                handling_time: 24,
                                passenger_type: "eu_pax_departure",
                            },
                            {
                                source: "start",
                                frac: 0.67,
                                handling_time: 24,
                                passenger_type: "eu_pax_transfer",
                            },
                        ],
                    },
                ],
            },
        ],
        screening: {
            optimal_balancing: false,
            pax_error_assignment: 0.05,
            partition_dimensions: [
                { name: "pier", selected: true },
                { name: "destination", selected: false },
                { name: "flight_type", selected: false },
                { name: "pax_type", selected: true },
            ],
            partition_assignment: [
                {
                    assignment: null,
                    pier: "A",
                    destination: null,
                    flight_type: null,
                    pax_type: "business",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "T",
                    destination: null,
                    flight_type: null,
                    pax_type: "business",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "A",
                    destination: null,
                    flight_type: null,
                    pax_type: "economy",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "T",
                    destination: null,
                    flight_type: null,
                    pax_type: "economy",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "A",
                    destination: null,
                    flight_type: null,
                    pax_type: "PRM",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "B",
                    destination: null,
                    flight_type: null,
                    pax_type: "economy",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "B",
                    destination: null,
                    flight_type: null,
                    pax_type: "business",
                    extra_handling_time: 10,
                },
                {
                    assignment: null,
                    pier: "B",
                    destination: null,
                    flight_type: null,
                    pax_type: "PRM",
                    extra_handling_time: 10,
                },
            ],
        },
    },
    {
        id: "taxi-current",
        name: "Taxi current",
        airport_component: "TaxiCurrent",
    },
    {
        id: "taxi-future",
        name: "Taxi future",
        airport_component: "TaxiFuture",
    },
    //TODO PUT THIS BACK
    /*{
      id: "arrival-pier-b",
      name: "Arrival Pier B",
      airport_component: "Arrival_Pier_B",
      start_point: "gate",
      passenger_proportions: DEFAULT_ARRIVAL_PASSENGER_PROPORTION,
      passenger_types: [
        {
          id: "eu_pax_arrival",
          label: "EU Pax Arrival",
        },
        {
          id: "tcn_vh_first_registration_pax_arrival",
          label: "TCN Visa Holders (First registration) Arrival",
        },
        {
          id: "tcn_ve_first_registration_pax_arrival",
          label: "TCN Visa Exempt (First registration) Arrival",
        },
        {
          id: "noss_tcn_vh_first_registration_pax_transfer_a",
          label: "TCN Visa Holders (First registration) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_ve_first_registration_pax_transfer_a",
          label: "TCN Visa Exempty (First registration) Transfer B->A (NOSS)",
        },
        {
          id: "tcn_vh_reentry_pax_arrival",
          label: "TCN Visa Holders (Re-entry) Arrival",
        },
        {
          id: "tcn_ve_reentry_pax_arrival",
          label: "TCN Visa Exempt (Re-entry) Arrival",
        },
        {
          id: "noss_tcn_vh_reentry_pax_transfer_a",
          label: "TCN Visa Holders (Re-entry) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_ve_reentry_pax_transfer_a",
          label: "TCN Visa Exempty (Re-entry) Transfer B->A (NOSS)",
        },
      ],
      entities: [
        {
          name: "egate",
          label: "eGates",
          type: "entity",
          display_order: 3,
          process_order: 3,
          elements: [
            {
              name: "eu-egate",
              label: "eGates",
              min_entities: 1,
              max_entities: 10,
              n_entities: 10,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.67,
                  handling_time: 30,
                  passenger_type: "eu_pax_arrival",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
          ],
          queues: {
            "eu-egate": ["eu-egate"],
          },
        },
        {
          name: "kiosk",
          label: "Kiosks",
          type: "entity",
          display_order: 1,
          process_order: 0,
          elements: [
            {
              name: "tcn-kiosk",
              label: "Kiosks",
              min_entities: 0,
              max_entities: 30,
              n_entities: 10,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "noss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "noss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "noss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "noss_tcn_ve_reentry_pax_transfer_a",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
          ],
          queues: {
            "tcn-kiosk": ["tcn-kiosk"],
          },
        },
        {
          name: "booth",
          label: "Booths",
          type: "entity",
          display_order: 0,
          process_order: 1,
          elements: [
            {
              name: "eu-booth",
              label: "EU Booths",
              min_entities: 1,
              max_entities: 10,
              n_entities: 3,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.33,
                  handling_time: 24,
                  passenger_type: "eu_pax_arrival",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
            {
              name: "tcn-booth",
              label: "TCN Booths",
              min_entities: 1,
              max_entities: 10,
              n_entities: 7,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_arrival",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "tcn_vh_first_registration_pax_arrival",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 45,
                  passenger_type: "tcn_ve_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_arrival",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "tcn_vh_reentry_pax_arrival",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 45,
                  passenger_type: "tcn_ve_reentry_pax_arrival",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
          ],
          queues: {
            "tcn-booth": ["tcn-booth", "eu-booth"],
            "eu-booth": ["eu-booth", "tcn-booth"],
          },
        },
      ],
    },
    {
      id: "transfer-b-a-oss",
      name: "Transfer B-A OSS",
      airport_component: "Transfer_BA_OSS",
      start_point: "gate",
      passenger_proportions: DEFAULT_ARRIVAL_PASSENGER_PROPORTION,
      passenger_types: [
        {
          id: "oss_eu_pax_transfer_a",
          label: "EU Pax Transfer B->A (OSS)",
        },
        {
          id: "oss_tcn_vh_first_registration_pax_transfer_a",
          label: "TCN Visa Holders (First registration) Transfer B->A (OSS)",
        },
        {
          id: "oss_tcn_ve_first_registration_pax_transfer_a",
          label: "TCN Visa Exempt (First registration) Transfer B->A (NOSS)",
        },
        {
          id: "oss_tcn_vh_reentry_pax_transfer_a",
          label: "TCN Visa Holders (Re-entry) Transfer B->A (OSS)",
        },
        {
          id: "oss_tcn_ve_reentry_pax_transfer_a",
          label: "TCN Visa Exempt (Re-entry) Transfer B->A (NOSS)",
        },
      ],
      entities: [
        {
          name: "kiosk",
          label: "Kiosks",
          type: "entity",
          display_order: 2,
          process_order: 0,
          elements: [
            {
              name: "tcn-kiosk",
              label: "Kiosks",
              min_entities: 0,
              max_entities: 30,
              n_entities: 10,
              max_size: 200,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "oss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "oss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "oss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "oss_tcn_ve_reentry_pax_transfer_a",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
          ],
          queues: {
            "tcn-kiosk": ["tcn-kiosk"],
          },
        },
        {
          name: "booth",
          label: "Booths",
          type: "entity",
          display_order: 0,
          process_order: 1,
          elements: [
            {
              name: "eu-booth",
              label: "EU Booths",
              min_entities: 1,
              max_entities: 10,
              n_entities: 10,
              passenger_types: [
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "oss_eu_pax_transfer_a",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
            {
              name: "tcn-booth",
              label: "TCN Booths",
              min_entities: 1,
              max_entities: 10,
              n_entities: 10,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 150,
                  passenger_type: "oss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 130,
                  passenger_type: "oss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 90,
                  passenger_type: "oss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "oss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 150,
                  passenger_type: "oss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.2,
                  handling_time: 130,
                  passenger_type: "oss_tcn_ve_reentry_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 90,
                  passenger_type: "oss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "oss_tcn_ve_reentry_pax_transfer_a",
                },
              ],
              planning: DEFAULT_PLANNING,
            },
          ],
          queues: {
            "tcn-booth": ["tcn-booth", "eu-booth"],
            "eu-booth": ["eu-booth", "tcn-booth"],
          },
        },
      ],
    },
    {
      id: "transfer-b-a-noss",
      name: "Transfer B-A NOSS",
      airport_component: "Transfer_BA_NOSS",
      start_point: "gate",
      passenger_proportions: DEFAULT_ARRIVAL_PASSENGER_PROPORTION,
      passenger_types: [
        {
          id: "noss_eu_pax_transfer_a",
          label: "EU Pax Transfer B->A (NOSS)",
        },
        {
          id: "noss_eu_pax_transfer_b",
          label: "EU Pax Transfer B->B (NOSS)",
        },
        {
          id: "tcn_vh_first_registration_pax_arrival",
          label: "TCN Visa Holders (First registration) Arrival",
        },
        {
          id: "tcn_ve_first_registration_pax_arrival",
          label: "TCN Visa Exempt (First registration) Arrival",
        },
        {
          id: "noss_tcn_vh_first_registration_pax_transfer_a",
          label: "TCN Visa Holders (First registration) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_ve_first_registration_pax_transfer_a",
          label: "TCN Visa Exempt (First registration) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_vh_first_registration_pax_transfer_b",
          label: "TCN Visa Holders (First registration) Transfer B->B (NOSS)",
        },
        {
          id: "noss_tcn_ve_first_registration_pax_transfer_b",
          label: "TCN Visa Exempt (First registration) Transfer B->B (NOSS)",
        },
        {
          id: "tcn_vh_reentry_pax_arrival",
          label: "TCN Visa Holders (Re-entry) Arrival",
        },
        {
          id: "tcn_ve_reentry_pax_arrival",
          label: "TCN Visa Exempt (Re-entry) Arrival",
        },
        {
          id: "noss_tcn_vh_reentry_pax_transfer_a",
          label: "TCN Visa Holders (Re-entry) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_ve_reentry_pax_transfer_a",
          label: "TCN Visa Exempt (Re-entry) Transfer B->A (NOSS)",
        },
        {
          id: "noss_tcn_vh_reentry_pax_transfer_b",
          label: "TCN Visa Holders (Re-entry) Transfer B->B (NOSS)",
        },
        {
          id: "noss_tcn_ve_reentry_pax_transfer_b",
          label: "TCN Visa Exempt (Re-entry) Transfer B->B (NOSS)",
        },
      ],
      entities: [
        {
          name: "kiosk",
          label: "Kiosks",
          display_order: 1,
          process_order: 0,
          type: "entity",
          elements: [
            {
              name: "tcn-kiosk",
              label: "Kiosks",
              n_entities: 27,
              min_entities: 0,
              max_entities: 30,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "noss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "noss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 0.8,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_arrival",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "noss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "noss_tcn_ve_reentry_pax_transfer_a",
                },
              ],
            },
          ],
          queues: {
            "tcn-kiosk": ["tcn-kiosk"],
          },
        },
        {
          name: "screening",
          label: "Screening",
          display_order: 0,
          process_order: 1,
          type: "entity",
          elements: [
            {
              name: "noss-screening",
              label: "Screening",
              n_entities: 6,
              min_entities: 6,
              max_entities: 6,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_eu_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_eu_pax_transfer_b",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_vh_first_registration_pax_transfer_b",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_ve_first_registration_pax_transfer_b",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "kiosk",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_ve_reentry_pax_transfer_a",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_vh_reentry_pax_transfer_b",
                },
                {
                  source: "gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "noss_tcn_ve_reentry_pax_transfer_b",
                },
              ],
            },
          ],
          queues: {
            "noss-screening": ["noss-screening"],
          },
        },
        {
          name: "border",
          label: "Border",
          display_order: 2,
          process_order: 2,
          type: "entity",
          elements: [
            {
              name: "eu-egate",
              label: "eGates",
              n_entities: 10,
              min_entities: 1,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 30,
                  passenger_type: "noss_eu_pax_transfer_a",
                },
              ],
              max_size: 50,
            },
            {
              name: "tcn-booth",
              label: "Booths",
              n_entities: 4,
              min_entities: 1,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "noss_tcn_vh_first_registration_pax_transfer_a",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 45,
                  passenger_type: "noss_tcn_ve_first_registration_pax_transfer_a",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 60,
                  passenger_type: "noss_tcn_vh_reentry_pax_transfer_a",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 45,
                  passenger_type: "noss_tcn_ve_reentry_pax_transfer_a",
                },
              ],
              max_size: 100,
            },
          ],
          queues: {
            "tcn-booth": ["tcn-booth", "eu-egate"],
            "eu-egate": ["eu-egate"],
          },
        },
      ],
    },
    {
      id: "border-b-departure",
      name: "Border B departure",
      airport_component: "border_B_departures",
  
      start_point: "checkin-or-gate",
      passenger_proportions: DEFAULT_DEPARTURE_PASSENGER_PROPORTION,
      passenger_types: [
        {
          id: "eu_pax_departure",
          label: "EU Pax Departure Pier B",
        },
        {
          id: "tcn_vh_first_registration_pax_departure",
          label: "TCN Visa Holders (First registration) Departure Pier B",
        },
        {
          id: "tcn_ve_first_registration_pax_departure",
          label: "TCN Visa Exempt (First registration) Departure Pier B",
        },
        {
          id: "tcn_vh_reentry_pax_departure",
          label: "TCN Visa Holders (Re-entry) Departure Pier B",
        },
        {
          id: "tcn_ve_reentry_pax_departure",
          label: "TCN Visa Exempt (Re-entry) Departure Pier B",
        },
        {
          id: "eu_pax_screening_only",
          label: "EU Pax Departure Pier A/T",
        },
        {
          id: "tcn_vh_first_registration_pax_screening_only",
          label: "TCN Visa Holders (First registration) Departure Pier A/T",
        },
        {
          id: "tcn_ve_first_registration_pax_screening_only",
          label: "TCN Visa Exempt (First registration) Departure Pier A/T",
        },
        {
          id: "tcn_vh_reentry_pax_screening_only",
          label: "TCN Visa Holders (Re-entry) Departure Pier A/T",
        },
        {
          id: "tcn_ve_reentry_pax_screening_only",
          label: "TCN Visa Exempt (Re-entry) Departure Pier A/T",
        },
        {
          id: "eu_pax_transfer",
          label: "EU Pax Transfer A->B",
        },
        {
          id: "tcn_vh_first_registration_pax_transfer",
          label: "TCN Visa Holders (First registration) Transfer A->B",
        },
        {
          id: "tcn_ve_first_registration_pax_transfer",
          label: "TCN Visa Exempt (First registration) Transfer A->B",
        },
        {
          id: "tcn_vh_reentry_pax_transfer",
          label: "TCN Visa Holders (Re-entry) Transfer A->B",
        },
        {
          id: "tcn_ve_reentry_pax_transfer",
          label: "TCN Visa Exempt (Re-entry) Transfer A->B",
        },
      ],
      entities: [
        {
          name: "screening",
          process_order: 0,
          display_order: 2,
          label: "Connector Screening",
          type: "entity",
          elements: [
            {
              name: "conn-screening",
              label: "Connector Screening",
              n_entities: 25,
              min_entities: 0,
              max_entities: 30,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_first_registration_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_first_registration_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_reentry_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_reentry_pax_screening_only",
                },
              ],
            },
          ],
          queues: {
            "conn-screening": ["conn-screening"],
          },
        },
        {
          name: "egate",
          label: "eGates",
          process_order: 1,
          display_order: 0,
          type: "entity",
          elements: [
            {
              name: "eu-egate",
              label: "eGates",
              n_entities: 10,
              min_entities: 0,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "screening",
                  frac: 0.67,
                  handling_time: 30,
                  passenger_type: "eu_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 0.67,
                  handling_time: 30,
                  passenger_type: "eu_pax_transfer",
                },
              ],
            },
          ],
          queues: {
            "eu-egate": ["eu-egate"],
          },
        },
        {
          name: "booth",
          process_order: 2,
          display_order: 1,
          label: "Booths",
          type: "entity",
          elements: [
            {
              name: "eu-booth",
              label: "EU Booths",
              n_entities: 5,
              min_entities: 0,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "screening",
                  frac: 0.33,
                  handling_time: 24,
                  passenger_type: "eu_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 0.33,
                  handling_time: 24,
                  passenger_type: "eu_pax_transfer",
                },
              ],
            },
            {
              name: "tcn-booth",
              label: "TCN Booths",
              n_entities: 3,
              min_entities: 0,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_departure",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_transfer",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_transfer",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_departure",
                },
                {
                  source: "screening",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_transfer",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_transfer",
                },
              ],
            },
          ],
          queues: {
            "tcn-booth": ["tcn-booth", "eu-booth"],
            "eu-booth": ["eu-booth", "tcn-booth"],
          },
        },
      ],
    },
    {
      id: "border-a-t-departure",
      name: "Border A-T departure",
      airport_component: "border_AT_departures",
  
      start_point: "checkin-or-gate",
      passenger_proportions: DEFAULT_DEPARTURE_PASSENGER_PROPORTION,
      passenger_types: [
        {
          id: "eu_pax_departure",
          label: "EU Pax Departure Pier T",
        },
        {
          id: "tcn_vh_first_registration_pax_departure",
          label: "TCN Visa Holders (First registration) Departure Pier T",
        },
        {
          id: "tcn_ve_first_registration_pax_departure",
          label: "TCN Visa Exempt (First registration) Departure Pier T",
        },
        {
          id: "tcn_vh_reentry_pax_departure",
          label: "TCN Visa Holders (Re-entry) Departure Pier T",
        },
        {
          id: "tcn_ve_reentry_pax_departure",
          label: "TCN Visa Exempt (Re-entry) Departure Pier T",
        },
        {
          id: "eu_pax_screening_only",
          label: "EU Pax Departure Pier A/B",
        },
        {
          id: "tcn_vh_first_registration_pax_screening_only",
          label: "TCN Visa Holders (First registration) Departure Pier A/B",
        },
        {
          id: "tcn_ve_first_registration_pax_screening_only",
          label: "TCN Visa Exempt (First registration) Departure Pier A/B",
        },
        {
          id: "tcn_vh_reentry_pax_screening_only",
          label: "TCN Visa Holders (Re-entry) Departure Pier A/B",
        },
        {
          id: "tcn_ve_reentry_pax_screening_only",
          label: "TCN Visa Exempt (Re-entry) Departure Pier A/B",
        },
        {
          id: "eu_pax_transfer",
          label: "EU Pax Transfer A->T",
        },
        {
          id: "tcn_vh_first_registration_pax_transfer",
          label: "TCN Visa Holders (First registration) Transfer A->T",
        },
        {
          id: "tcn_ve_first_registration_pax_transfer",
          label: "TCN Visa Exempt (First registration) Transfer A->T",
        },
        {
          id: "tcn_vh_reentry_pax_transfer",
          label: "TCN Visa Holders (Re-entry) Transfer A->T",
        },
        {
          id: "tcn_ve_reentry_pax_transfer",
          label: "TCN Visa Exempt (Re-entry) Transfer A->T",
        },
      ],
      entities: [
        {
          name: "screening",
          process_order: 0,
          display_order: 1,
          label: "Connector Screening",
          type: "entity",
          elements: [
            {
              name: "conn-screening",
              label: "Connector Screening",
              n_entities: 25,
              min_entities: 0,
              max_entities: 30,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_first_registration_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_first_registration_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_vh_reentry_pax_screening_only",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "tcn_ve_reentry_pax_screening_only",
                },
              ],
            },
          ],
          queues: {
            "conn-screening": ["conn-screening"],
          },
        },
        {
          name: "walk",
          process_order: 1,
          display_order: 2,
          label: "Walking time from Screening to Border A-T",
          type: "transit",
          passenger_types: [
            {
              source: "screening",
              frac: 1,
              handling_time: 720,
              passenger_type: "eu_pax_departure",
            },
            {
              source: "screening",
              frac: 1,
              handling_time: 720,
              passenger_type: "tcn_vh_first_registration_pax_departure",
            },
            {
              source: "screening",
              frac: 1,
              handling_time: 720,
              passenger_type: "tcn_ve_first_registration_pax_departure",
            },
            {
              source: "screening",
              frac: 1,
              handling_time: 720,
              passenger_type: "tcn_vh_reentry_pax_departure",
            },
            {
              source: "screening",
              frac: 1,
              handling_time: 720,
              passenger_type: "tcn_ve_reentry_pax_departure",
            },
          ],
        },
        {
          name: "booth",
          process_order: 2,
          display_order: 0,
          label: "Booths",
          type: "entity",
          elements: [
            {
              name: "eu-booth",
              label: "EU Booths",
              n_entities: 3,
              min_entities: 0,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "walk",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 24,
                  passenger_type: "eu_pax_transfer",
                },
              ],
            },
            {
              name: "tcn-booth",
              label: "TCN Booths",
              n_entities: 3,
              min_entities: 0,
              max_entities: 10,
              planning: DEFAULT_PLANNING,
              passenger_types: [
                {
                  source: "walk",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_departure",
                },
                {
                  source: "walk",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_first_registration_pax_transfer",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_first_registration_pax_transfer",
                },
                {
                  source: "walk",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_departure",
                },
                {
                  source: "walk",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_departure",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 150,
                  passenger_type: "tcn_vh_reentry_pax_transfer",
                },
                {
                  source: "checkin-or-gate",
                  frac: 1,
                  handling_time: 130,
                  passenger_type: "tcn_ve_reentry_pax_transfer",
                },
              ],
            },
          ],
          queues: {
            "tcn-booth": ["tcn-booth", "eu-booth"],
            "eu-booth": ["eu-booth", "tcn-booth"],
          },
        },
      ],
    },*/
];
const findInfrastructureTemplate = (id) => exports.infrastructureTemplates.find((i) => i.id === id);
exports.findInfrastructureTemplate = findInfrastructureTemplate;
