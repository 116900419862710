import { AreaChartOutlined, CalendarOutlined, HomeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import React, { lazy } from "react";
import AppLayout from "./AppLayout";
import { Result, Spin } from "antd";
import HomeButton from "./components/UI/HomeButton";
import RetryButton from "./components/UI/RetryButton";
import { useRouteError } from "react-router";

const SimulationParamtersScreen = lazy(() => import("./components/SimulationParameters/SimulationParametersScreen"));
const Home = lazy(() => import("./components/Home/Home"));
const Simulation = lazy(() => import("./components/Simulation/Simulation"));
const Comparator = lazy(() => import("./components/Comparator/Comparator"));
const ScenarioDayScreen = lazy(() => import("./components/ScenarioDay/ScenarioDayScreen"));

const LoadingSpin = () => <Spin spinning={true} tip="Loading..." style={{ textAlign: "center", width: "100%", marginTop: "100px" }} />;

export const NotFound = () => <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." extra={<HomeButton />} />;
export const ErrorBoundary = () => {
  const error = useRouteError();
  return <Result status="500" title={error.toString()} subTitle="Sorry, something went wrong" extra={<RetryButton />} />;
};

export const routes = [
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        id: "home",
        path: "/",
        index: true,
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <Home />
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        title: "Home",
        icon: HomeOutlined,
        handle: {
          title: "Home",
        },
      },
      {
        id: "simulation-list",
        path: "simulations",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <SimulationParamtersScreen />
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        title: "Parametrize simulation",
        icon: UnorderedListOutlined,
        handle: {
          title: "Simulations",
        },
      },
      {
        id: "simulation",
        path: "simulations/:simulationId",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <Simulation />
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        title: "Visualize simulation",
        handle: {
          title: "Visualize simulation",
        },
      },
      {
        id: "comparator",
        path: "comparator",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <Comparator />
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        title: "Scenario comparator",
        icon: AreaChartOutlined,
        handle: {
          title: "Scenario comparator",
        },
      },
      {
        id: "scenario-day",
        path: "scenario-day",
        element: (
          <React.Suspense fallback={<LoadingSpin />}>
            <ScenarioDayScreen />
          </React.Suspense>
        ),
        errorElement: <ErrorBoundary />,
        title: "Scenario day",
        icon: CalendarOutlined,
        handle: {
          title: "Scenario days",
        },
      },
      {
        id: "not-found",
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

const appendChildren = (routes) => [].concat(...routes.map((route) => (route.children ? appendChildren(route.children) : route)));

export const navigationMenuRoutes = appendChildren(routes)
  .filter((route) => !!route.icon)
  .map((route) => ({
    id: route.id,
    path: route.path,
    title: route.title,
    icon: route.icon,
  }));
