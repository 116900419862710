import React from "react";

const TstWarning = () => {
  const prodURL = "bac.jetpack.ai"; // TODO : change this to the production URL
  const demoURL = "d308z9cettue7s.cloudfront.net";
  return /* process.env.NODE_ENV !== "production" && */ window.location.host !== prodURL &&
    window.location.host !== demoURL &&
    !window.location.host.includes("localhost") ? (
    <div style={{ display: "block", backgroundColor: "red", color: "white", textAlign: "center" }}>
      WARNING ! This environnement is a prototype dedicated to developement. Production environnement is{" "}
      <a style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }} href={`https://${prodURL}`}>
        {prodURL}
      </a>
    </div>
  ) : null;
};

export default TstWarning;
