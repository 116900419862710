/* eslint-disable react/jsx-pascal-case */
import { Button, Select } from "antd";
import { infrastructureTemplates } from "shared/Infrastructure";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";

const InfrastructureSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const infrastructureId = searchParams.get("infrastructureId");

  return (
    <>
      <div>
        Infrastructure:
        <Select
          style={{ width: 270, marginLeft: 10 }}
          onChange={(infrastructureId) => {
            // if (location.pathname.includes("scenario-day")) {
            //   navigate(`/scenario-day/${infrastructureId}`);
            // } else {
            //   navigate(`/simulations/${infrastructureId}`);
            // }
            if (location.pathname.includes("simulations")) {
              navigate(`/simulations${infrastructureId ? `?infrastructureId=${infrastructureId}` : ""}`, { replace: true });
            } else {
              setSearchParams({ infrastructureId });
            }
          }}
          value={infrastructureTemplates.filter((infrastructure) => infrastructure.id === infrastructureId)?.[0]?.id}
        >
          {infrastructureTemplates
            //.filter((infrastructure) => !(infrastructure.id.includes("future") && location.pathname.includes("scenario-day")))
            .map(({ name, id }) => {
              return (
                <Select.Option key={name} value={id}>
                  {name}
                </Select.Option>
              );
            })}
        </Select>
        <Button type="link" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faMap} />
        </Button>
      </div>
    </>
  );
};

export default InfrastructureSelection;
