import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import LoginTheme from "./components/LoginTheme/LoginTheme";
import configureAuth from "./configureEnvironment/configureAuth";
import "@aws-amplify/ui-react/styles.css"; // default theme
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import { routes } from "./routes";

const { REACT_APP_SKIP_AUTH } = process.env;
const shouldSkipAuth = REACT_APP_SKIP_AUTH === "SKIP";

const queryClient = new QueryClient();

if (!shouldSkipAuth) {
  configureAuth();
}

const AuthenticatorWrapper = ({ children }) =>
  shouldSkipAuth ? (
    children
  ) : (
    <Authenticator theme={LoginTheme} hideSignUp>
      {({ signOut, user }) => children}
    </Authenticator>
  );

const router = createBrowserRouter(routes);

const App = () => (
  <AmplifyProvider>
    <AuthenticatorWrapper>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {window.location.hostname === "localhost" && <ReactQueryDevtools />}
      </QueryClientProvider>
    </AuthenticatorWrapper>
  </AmplifyProvider>
);
export default App;
